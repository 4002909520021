import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "conditionalFieldset" ]

  connect(){
    this.refresh()
  }

  refresh(e){
    for (const fieldset of this.conditionalFieldsetTargets) {
      this.processFieldset(fieldset)
    }
  }

  processFieldset(fieldset){
    fieldset.disabled = !this.conditionsPassed(fieldset)
  }

  conditionsPassed(fieldset){
    const conditions = JSON.parse(fieldset.dataset.conditions)

    return (conditions.filter(condition => this.conditionPassed(condition)).length > 0)
  }

  conditionPassed({ field, type, values }){
    return values.includes(this.getCurrentFieldValue(field))
  }

  getCurrentFieldValue(field){
    return Object.fromEntries(new FormData(this.element))[field]
  }
}
